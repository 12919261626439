import { createConsumer } from "@rails/actioncable";

const consumer = createConsumer();

const createLocationChannel = (token) => {
  return consumer.subscriptions.create(
    { 
      channel: "LocationChannel",
      token: token
    }, 
    {
      connected() {
        console.log('Connected to LocationChannel');
      },

      disconnected() {
        console.log('Disconnected from LocationChannel');
      },

      rejected() {
        console.log('Rejected from LocationChannel');
      },

      received(_data) {
        console.log('Received data from LocationChannel');
      },

      updateLocation(coordinates) {
        this.perform('update_location', {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        });
      },

      unsubscribed() {
        const event = new CustomEvent('locationChannelUnsubscribed', {
          detail: {
            reason: 'channel_unsubscribed',
            timestamp: Date.now(),
            token: token
          }
        });
        document.dispatchEvent(event);
      }
    }
  );
};

export default createLocationChannel;
